import AttachmentTypeRepository from '@/shared/http/repositories/socialProject/attachment-type'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewRegister from '@/components/layout/NewRegister/newRegister.vue'
import AttachmentType from '@/shared/models/attachmentType'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'NovoTipoDeAnexo',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewRegister.name]: NewRegister
  },

  data() {
    return {
      placeholder: 'Nome',
      title: 'Tipo de Anexo',
      attachmentType: new AttachmentType(),
      isNew: true
    }
  },

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Tipos de Anexo',
            href: this.$router.resolve({ name: 'AttachmentType' }).href
          },
          {
            text: 'Novo Tipo de Anexo',
            active: true
          }
        ]
      }
    }
  },

  methods: {
    create(attachmentType) {
      loading.push()
      AttachmentTypeRepository.Create(attachmentType)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Tipo de Anexo criado com sucesso', 'NOVO TIPO DE ANEXO')
          Promise.resolve()
          return this.$router.replace({ name: 'AttachmentType' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar tipo de anexo', 'ERRO')
        })
    }
  }
}
