export default {
  name: 'NewRegister',

  props: {
    placeholder: String,
    title: String,
    data: Object,
    isNew: Boolean,
  },

  data: () => ({
  }),

  computed: {
    state() {
      if (this.data && this.data.name) {
        return this.data.name.length >= 2
      }
    },
    disable() {
      if (this.isNew === true) {
        if (this.data && this.data.name) {
          return this.data.name.length < 2
        }
      } else {
          if (this.data && this.data.name) {
            return this.data.name.length < 2
        }
      }
      return true
    }
  },

  methods: {
    backTo() {
      this.$router.go(-1)
    },

    save() {
      this.isNew 
        ? this.$emit('create', this.data)
        : this.$emit('update', this.data)
    }
  }
}